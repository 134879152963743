/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
* {
  margin: 0;
  padding: 0;
}

html {
  margin: 0 !important;
  overflow: auto !important;
}

body {
  color: #5e5c7f;
  background-color: #f9f9ff;
  font-family: $fontstack;
  font-size: 16px;
  line-height: 1.7;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.relative {
  position: relative;
}

/*=================================================================*/
/*                      TYPOGRAPHY                              
/*=================================================================*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #454360;
  font-family: $fonthead;
  font-weight: 700;
  margin: 20px 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

a {
  color: $colorpink;
  outline: 0;
  @include transition(0.3s);
  &:hover {
    color: $colordark;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
}

blockquote {
  padding: 20px 20px;
  margin: 0 0 20px;
  font-size: 16px;
  background: #f7f7f7;
  border-radius: 10px;
}

blockquote p {
  line-height: 1.6;
}

/* === Pre === */
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
}

/*=================================================================*/
/*                      PRELOADER                              
/*=================================================================*/
.infinity {
  width: 120px;
  height: 60px;
  position: relative;
  div,
  span {
    position: absolute;
  }
  div {
    top: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    animation: rotate 6.9s linear infinite;
    span {
      left: -8px;
      top: 50%;
      margin: -8px 0 0 0;
      width: 16px;
      height: 16px;
      display: block;
      background: $colorpink;
      box-shadow: 2px 2px 8px rgba($colorpink, 0.09);
      border-radius: 50%;
      transform: rotate(90deg);
      animation: move 6.9s linear infinite;
      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        background: inherit;
        top: 50%;
        left: 50%;
        margin: -7px 0 0 -7px;
        box-shadow: inherit;
      }
      &:before {
        animation: drop1 0.8s linear infinite;
      }
      &:after {
        animation: drop2 0.8s linear infinite 0.4s;
      }
    }
    &:nth-child(2) {
      animation-delay: -2.3s;
      span {
        animation-delay: -2.3s;
      }
    }
    &:nth-child(3) {
      animation-delay: -4.6s;
      span {
        animation-delay: -4.6s;
      }
    }
  }
}

.infinityChrome {
  width: 128px;
  height: 60px;
  div {
    position: absolute;
    width: 16px;
    height: 16px;
    background: $colorpink;
    box-shadow: 2px 2px 8px rgba($colorpink, 0.09);
    border-radius: 50%;
    animation: moveSvg 6.9s linear infinite;
    -webkit-filter: url(#goo);
    filter: url(#goo);
    transform: scaleX(-1);
    offset-path: path(
      'M64.3636364,29.4064278 C77.8909091,43.5203348 84.4363636,56 98.5454545,56 C112.654545,56 124,44.4117395 124,30.0006975 C124,15.5896556 112.654545,3.85282763 98.5454545,4.00139508 C84.4363636,4.14996252 79.2,14.6982509 66.4,29.4064278 C53.4545455,42.4803627 43.5636364,56 29.4545455,56 C15.3454545,56 4,44.4117395 4,30.0006975 C4,15.5896556 15.3454545,4.00139508 29.4545455,4.00139508 C43.5636364,4.00139508 53.1636364,17.8181672 64.3636364,29.4064278 Z'
    );
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background: inherit;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      box-shadow: inherit;
    }
    &:before {
      animation: drop1 0.8s linear infinite;
    }
    &:after {
      animation: drop2 0.8s linear infinite 0.4s;
    }
    &:nth-child(2) {
      animation-delay: -2.3s;
    }
    &:nth-child(3) {
      animation-delay: -4.6s;
    }
  }
}

@keyframes moveSvg {
  0% {
    offset-distance: 0%;
  }
  25% {
    background: $colorblue;
  }
  75% {
    background: $coloryellow;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes rotate {
  50% {
    transform: rotate(360deg);
    margin-left: 0;
  }
  50.0001%,
  100% {
    margin-left: -60px;
  }
}

@keyframes move {
  0%,
  50% {
    left: -8px;
  }
  25% {
    background: $coloryellow;
  }
  75% {
    background: $colorblue;
  }
  50.0001%,
  100% {
    left: auto;
    right: -8px;
  }
}

@keyframes drop1 {
  100% {
    transform: translate(32px, 8px) scale(0);
  }
}

@keyframes drop2 {
  0% {
    transform: translate(0, 0) scale(0.9);
  }
  100% {
    transform: translate(32px, -8px) scale(0);
  }
}

#preloader {
  background: $colordark;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1031;
  .outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.light {
    background: #f9f9ff;
  }
}

.goo-outer {
  display: none;
}
